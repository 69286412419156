<template>
  <div class="wrapper">
    <ClientModify/>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import AdminExtension from '../AdminExtension'
import ClientModify from './ClientModify'

export default AdminExtension.extend({
    name: 'ClientAdd',
    components: {
        ClientModify,
    },
})
</script>

